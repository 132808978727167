<template>
  <div>
    <a-card :title="$t('创建品质调整单')">
      <a-button slot="extra" type="primary" ghost icon="left" @click="$router.go(-1)">{{ $t("返回") }}</a-button>

      <a-spin :spinning="createLoading">
        <a-form-model ref="form" :model="orderForm" :rules="rules" :label-col="{ span: 10 }" :wrapper-col="{ span: 14 }">
          <a-row>
            <a-col :span="24" style="width: 380px">
              <a-form-model-item prop="number" :label="$t('品质调整单单号')">
                <a-input v-model="orderForm.number" allowClear />
              </a-form-model-item>
            </a-col>

            <a-col :span="24" style="width: 380px">
              <a-form-model-item prop="warehouse" :label="$t('品质调整仓库')">
                <a-select v-model="orderForm.warehouse" style="width: 100%" @change="onChangeWarehouse">
                  <a-select-option v-for="item in warehouseItems" :key="item.id" :value="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="width: 380px">
              <a-form-model-item prop="remark" :label="$t('备注')">
                <a-input v-model="orderForm.remark" allowClear />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>

        <a-divider></a-divider>

        <div>
          <a-space>
            <a-button type="primary" @click="openMaterialModal">{{ $t("选择货品") }}</a-button>
          </a-space>

          <div style="margin-top: 16px">
            <a-table rowKey="id" size="small" :columns="columns" :data-source="materialItems" :pagination="false">
              <template slot="adjust_quantity" slot-scope="value, item">
                <a-input-number v-model="item.adjust_quantity" size="small" />
              </template>
              <template slot="location_in" slot-scope="value, item">
                <LocationSearchSelect
                  v-model="item.location_in"
                  :defaultItem="{ location: item.location_in, location_number: item.location_in_number }"
                  :placeholder="$t('请输入或选择库位')"
                  :warehouse="orderForm.warehouse"
                  size="small"
                />
              </template>

              <template slot="action" slot-scope="value, item, index">
                <a-button-group size="small">
                  <a-button type="danger" @click="removeMaterial(index)">{{ $t("删除") }}</a-button>
                </a-button-group>
              </template>
            </a-table>
          </div>
        </div>
      </a-spin>

      <div style="margin-top: 32px">
        <a-popconfirm :title="$t('确定创建吗?')" @confirm="create">
          <a-button type="primary" :loading="createLoading">{{ $t("创建") }}</a-button>
        </a-popconfirm>
      </div>
    </a-card>

    <SubBatchSelectModal
      v-model="materialModalVisible"
      :warehouse="orderForm.warehouse"
      @select="onSelectMaterial"
      @confirm="onConfirm"
    />
  </div>
</template>

<script>
import { warehouseOption } from "@/api/option";
import { qualityAdjustOrderNumber, qualityAdjustOrderCreate } from "@/api/stockTransfer";
import { columns } from "./columns";
import { rules } from "./rules";

export default {
  components: {
    LocationSearchSelect: () => import("@/components/LocationSearchSelect/index"),
    SubBatchSelectModal: () => import("@/components/SubBatchSelectModal"),
  },
  inject: ["reloadPage"],
  data() {
    return {
      rules,
      columns,

      orderForm: { number: undefined, warehouse: undefined, remark: undefined },
      materialItems: [],
      createLoading: false,
      materialModalVisible: false,
    };
  },
  computed: {
    currentWarehouse() {
      return this.$store.state.user.currentWarehouse;
    },
    defaultWarehouse() {
      return this.$store.state.user.defaultWarehouse;
    },
  },
  methods: {
    initData() {
      qualityAdjustOrderNumber().then((data) => {
        this.orderForm.number = data.number;
      });

      warehouseOption({ page_size: 999999 }).then((data) => {
        this.warehouseItems = data.results;
        this.orderForm = { ...this.orderForm, warehouse: this.defaultWarehouse };
      });
    },
    onChangeWarehouse() {
      this.materialItems = [];
    },
    removeMaterial(index) {
      this.materialItems.splice(index, 1);
    },
    create() {
      if (this.materialItems.length == 0) {
        this.$message.warn(this.$t("未添加品质调整货品"));
        return;
      }

      for (const materialItem of this.materialItems) {
        if (!materialItem.adjust_quantity) {
          this.$message.warn(this.$t("无品质调整数量"));
          return;
        }
      }

      this.$refs.form.validate((valid) => {
        if (valid) {
          this.createLoading = true;
          const formData = { ...this.orderForm, quality_adjust_material_items: this.materialItems };
          qualityAdjustOrderCreate(formData)
            .then((data) => {
              this.$message.success(this.$t("创建成功"));
              this.$router.go(-1);
              // this.reloadPage();
            })
            .finally(() => {
              this.createLoading = false;
            });
        }
      });
    },
    openMaterialModal() {
      if (!this.orderForm.warehouse) {
        this.$message.warn(this.$t("请先选择仓库"));
        return;
      }

      this.materialModalVisible = true;
    },
    onConfirm(items) {
      for (const item of items) {
        this.onSelectMaterial(item);
      }
    },
    onSelectMaterial(item) {
      console.log(item)
      this.materialItems.splice(0, 0, {
        sub_batch_out: item.id,
        location_in: item.location,
        adjust_quantity: 0,
        type: item.status === "qualified" ? "adjust_unqualified" : "adjust_qualified",
        material_number: item.material_number,
        material_name: item.material_name,
        batch_number: item.batch_number,
        pallet_number: item.pallet_number,
        location_out_number: item.location_number,
        location_in_number: item.location_number,
        status_display: item.status_display,
        remain_quantity: item.remain_quantity,
      });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
